import {DocType} from "../data";
import React from "react";
import {css} from '@emotion/css';
import {Card} from "antd";

export function Doc(props: { doc: DocType }) {
    const {doc} = props;
    let {title, content} = doc;
    const lines = content.split('\n');
    return <Card id={doc.title} title={getTitle(doc)} className={docClass}>
        {
            lines.map((line, index) => <p className={lineClass} key={index}>{line}</p>)
        }
    </Card>;
}

var docClass = css`
  margin: .456em;
  p {
    text-indent: 1em;
  }
  @media screen and (min-width: 780px) {
    p {
      text-indent: 2em;
    }
  }

`;

var titleClass = css`
`;

var lineClass = css`
  text-indent: 2em;
`;

function getTitle(doc: DocType) {
    return (<div className={titleClass}>
        <span>
            {doc.title}
        </span>
        {doc.author && (<sub>&nbsp;{doc.author}</sub>)}
    </div>)
}
