import React, {} from 'react';
import './App.css';
import {docs} from "./data";
import {Anchor, Divider, Layout} from 'antd';
import {AnchorLinkItemProps} from "antd/es/anchor/Anchor";
import {Doc} from "./components/Doc";
import {css} from "@emotion/css";

export function App(): React.ReactElement<{}> {
    const left = [] as React.ReactElement[];
    const items = [] as AnchorLinkItemProps[];
    for (const [index, doc] of Object.entries(docs)) {
        left.push(<Doc doc={doc} key={doc.title}></Doc>);
        items.push({
           key: doc.title,
           href: `#${doc.title}`,
           title: `${Number(index) + 1}. ${doc.title}`,
        });
    }
    return (<>
        <Layout>
            <Layout.Content>
                {left}
            </Layout.Content>
            <Layout.Sider theme={'light'}>
                <Anchor items={items}>
                </Anchor>
                <div className={contact}>
                    <Divider></Divider>
                    联系: i@memo.cool
                </div>
            </Layout.Sider>
        </Layout>
    </>);
}

function Header(){
    return <div>Header</div>;
}

var contact = css`
  padding: 0 .6em;
  text-align: center;
  position: sticky;
  top: 9em;
`;
