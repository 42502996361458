export type DocType = {
    title: string,
    content: string,
    author?: string,
}
export const docs = [] as DocType[];

docs.push(
    {
        title: '阳光路十七号',
        author: '王虹莲',
        content: `阳光路十七号
王虹莲
新婚后不到一个月，他就出去打工了。他们山村的男人几乎全出去谋活路，山上的东西实在是不能养活他们。

她在家里，种地、养猪、赡养老人，等待着他从远方来寄来的信和钱。

每个月，他都会给家里寄来钱，或多或少。她似孩子一样，跑到储蓄所存起来，舍不得花掉一分钱。他们文化都不高，写封家书还是够用的。他的字丑陋，可是她喜欢，那字里行间，满是对她的牵挂和惦念。她也回信，羞涩地表达着惦记。正是新婚，她的每一瓣心花里，全是那个黑黑瘦瘦的男子。

他的地址她早就背下来了——阳光路十七号。

阳光路，多好听的名字。在那个繁华的大城市，这条路一定是铺满了金灿灿的阳光。何况，他在来信中说，阳光路是一条非常漂亮的路，绿阴蔽日，碎石铺就，这里条件相当好，住的是有露台的那种房子，虽然是打工，可觉得并不苦。

于是她的想像就更加完美，问，那阳台上有杜鹃花吗?有水仙花吗?那围墙上青藤爬满了吗?这种幻想让她对外面的世界充满了好感。所以，等待着阳光路十七号的来信成了她最大的快乐。

她喜欢听他描述外面的世界，那些红白相间的房子，那些穿着漂亮衣服的女孩子，那悠扬的钢琴声;他还说起过麦当劳，说，什么时候你来，我带你去吃。

但那年的春节，他却没有回来。他说，公司组织去海南旅游，机会难得，还是明年再回去吧。她逢人便说，我们家男人去海南旅游了，公司组织的。好像公司是个很气派的词，好像海南是国外一样。

存折上的钱越来越多了，她跟他说，明年你回来，我们一起盖个新房子吧。他们在信上的计划是那么美好，盖个新房子，买几只小猪仔，再种点玉米，生一个小孩子。想着想着，她就会甜蜜地笑。

他离开家快两年了，她想他想得快发了疯了。于是她准备动身去找他，想给他一个惊喜。坐了三天三夜的火车，她到达了那个城市。那真是一个美丽的大都市，她一下子就晕了，如果不是警察帮助，她简直分不清东南西北了。她把写着“阳光路十七号”的纸条递给警察。警察说，很远的地方，在郊区呢，离城市还有两个小时的车程。她呆住，以为听错了，他明明说是在市中心啊。

坐了两个小时的车，她又打听这个地方，有人指给她说，往前走，那边搭的简易棚子就是!

她终于看到一块破牌子上写着——阳光路十七号。

那是一块简陋的木牌子，上面有水泥和白灰。她也看到了那些简陋的房子，真是红白相间，红的砖，上面画着白线。而刚才路过那些漂亮的小区时，她也的确看到了带露台的房子，听到了钢琴声，可那都是别人的快乐。

旁边的人说，这片大楼快盖完了，这片简陋的房子也快拆除了，这帮农民工也应该回家了。他们在这儿干了快两年了，为挣钱都舍不得回家，可春节时老板跑了，连路费都没给他们留。

站在那简陋的房子前，她哭了。她没有去找他，而是又坐三天三夜的火车回了家。

回家后她写信给他:我想你了，回家吧。

一个月后，他带着大包小包回了家，当然，还带着一份不再新鲜了的麦当劳。她让他吃，他说，你吃，我在外面总吃。她含着眼泪吃完那个叫汉堡的东西，然后说，不好吃，不如红薯粥好

喝呢，怪不得你说吃腻了。

整整一夜，他给她讲外面的世界，一直说阳光路十七号，她听着，在黑暗中流下眼泪。最后，她握住他的手:因为有你，那条路才叫阳光路。

她一直没有说，她去过阳光路十七号。

那是她一个幸福而心酸的秘密。`,
    },
    {
        title: '最大的麦穗',
        content: `柏拉图和他的老师苏格拉底，学生亚里士多德并称为希腊三贤，都是古希腊最伟大的哲学家和思想家之一。

有一天，柏拉图问老师苏格拉底:什么是爱情？

苏格拉底说:我请你穿越这片麦田，去摘一株最大最金黄的麦穗回来，但是有个规则——你不能走回头路，而且你只能摘一次。

于是柏拉图去做了。许久之后，他却空着双手回来了。

苏格拉底问他怎么空手回来了？

柏拉图说道:当我走在田间的时候，曾看到过几株特别大特别灿烂的麦穗，可是，我总想著前面也许会有更大更好的，于是就没有摘；但是，我继续走的时候，看到的麦穗，总觉得还不如先前看到的好，所以我最后什么都没有摘到。

苏格拉底意味深长地说:这，就是爱情。

又一天，柏拉图问老师苏格拉底:什么是婚姻？

苏格拉底说:我请你穿越这片树林，去砍一棵最粗最结实的树回来好放在屋子里做圣诞树，但是有个规则——你不能走回头路，而且你只能砍一次。

于是柏拉图去做了。许久之后，他带了一棵并不算最高大粗壮却也不算赖的树回来了。

苏格拉底问他怎么只砍了这样一棵树回来？

柏拉图说道:当我穿越树林的时候，看到过几棵非常好的树，这次，我吸取了上次摘麦穗的教训，看到这棵树还不错，就选它了，我怕我不选它，就又会错过了砍树的机会而空手而归，尽管它并不是我碰见的最棒的一棵。

这时，苏格拉底意味深长地说:这，就是婚姻。

还有一次，柏拉图问老师苏格拉底:什么是幸福？

苏格拉底说:我请你穿越这片田野，去摘一朵最美丽的花，但是有个规则——你不能走回头路，而且你只能摘一次。

于是柏拉图去做了。许久之后，他捧著一朵比较美丽的花回来了。

苏格拉底问他:这就是最美丽的花了？

柏拉图说道:当我穿越田野的时候，我看到了这朵美丽的花，我就摘下了它，并认定了它是最美丽的，而且，当我后来又看见很多很美丽的花的时候，我依然坚持着我这朵最美的信念而不再动摇。所以我把最美丽的花摘来了。

这时，苏格拉底意味深长地说:这，就是幸福。

面对无法回头的人生，我们只能做三件事:郑重地选择，争取不留下遗憾；如果遗憾了，就理智地面对它，然后争取改变；假若也不能改变，就勇敢地接受，不要后悔，继续朝前走。
     `,
    },
    {
        title: '最初的温暖',
        author: '邹扶澜',
        content: `2014 年云南省初中学业水平考试

语文试题卷

（四）阅读下面文章，完成 19～23 题。（13分）

最初的温暖

邹扶澜

① 男孩小时候家里很穷，甚至有时连上学的本子也买不起，常常用完了正面用反面。一天，班里来了位新生，是个漂亮的女孩，扎着鲜艳的蝴蝶结，背着一个让全班同学都眼红的书包。

② 女孩跟他成了同桌。她的文具盒里装满了各种漂亮的笔和橡皮，她还有很多崭新的方格本，甚至有精装的笔记本。男孩偷眼看，心里既嫉妒又眼馋。

③ 男孩自尊心很强，怕女孩笑话，本子用完便不再用反面，也就不记笔记，只靠脑子记。女孩感到很奇怪，有一天问他，他说：“会了，不用记了。”女孩好像明白了什么，主动拿出两个本子给他，男孩拒绝了，女孩羞红了脸，几天不和他说话。有一次，班里组织看电影，每人交5分钱，男孩没有钱，就没有报名。女孩高兴地跑到他跟前，说：“明天下午看电影，我替你把钱交了。”男孩气恼地说：“我不去，用不着你给我交。。”女孩委屈地哭了，说：“你以后还给我还不行吗?”男孩没有再说话，勉强答应了她。

④ 第二年，女孩的家要搬了，男孩不知道要搬到哪儿，只觉得有一种留恋的感觉，仿佛丢了东西似的。知道她走的日期后，那一天他没有去上学，早早地候在一个十字路口----县城开往外地的车辆都要经过这。终于，一辆大货车开了过来，女孩和她的哥哥站在车厢里。女孩看见了他，高兴地向他挥，男孩想跟她说点什么可没有说出来，车速很快，一溜烟从眼前过去了。

⑤ 第二天上学，班里一个女同学给男孩送来一个沉甸甸的牛皮纸包，说是同桌留给他的。男孩打开，是厚厚一摞没用过的各式作业本，还有她用过的漂亮文具盒，里面装满了铅笔，还有一支能抽水的钢笔。笔中间夹着一张纸条，上面写着：“送给我最好的同桌。”

⑥ 男孩哭了，他突然觉得，从那天起，他的心知道了什么叫疼痛。

⑦ 那个文具盒，他一直保存着，有时候拿出来看看，眼前会晃动着一对鲜艳的蝴蝶结，一跳，一跳……

⑧ 几十年过去了，男孩读完大学，又读了博士，打拼多年后成为一家上市公司的老总。阅尽沧桑后，他越来越怀念那个当年跟他同桌的小女孩，于是在报纸上登了寻人启事，寻找那个叫王丽晨的女孩。

⑨ 电视台的记者闻讯后，邀请他做了一次访谈，面对现场的许多观众，他把藏在心底的那段往事说了出来，说到动情处，他几次哽咽，说如果能见一见当年同桌的女孩，此生也就无憾了。

⑩ 主持人问他：“为什么非要见她呢?相见不如怀念，保留最初的那份美好，为她祝福，不是很好吗?”

⑪ 他摇了摇头。

⑫ 主持人又说：“丽晨现在已经步入中年，也许已经发福，也许过得很不如意，如果是这样，你还会执意见她吗?”

⑬ 他说：“是的，我想当面对她说声谢谢。另外，如果她有什么难处的话，我想尽自己所能帮她一把。这么多年来，我一直想着她，甚至没有一天忘记过她，小时候，因为贫穷，我看过太多的冷眼，但她给了我最初的温暖，这成了我生命的动力……”他已经泪光莹莹，“我想看见她还美好地生活着，跟我一样呼吸着新鲜的空气，沭浴着温暖的阳光……”

⑭ 几天后，他收到一封信：“亲爱的刘同学，不瞒你说，那天我就坐在观众席上。之所以不见你，并不是因为你太富有，或者我已经老了，而是有些美只适合放在心里，就像一坛陈酒，—旦打开味道就淡了。感谢这么多年来，我一直活在你心里，如果你对我心存感激，那就在心里保存那份最初的美好并把它传递下去吧。”

⑮ 信是那位主持人写的，王丽晨是他的妹妹。她已于三年前病逝。
（选自《微型小说百年经典》，有删改）

19. 文章以“最初的温暖”为题可谓独具匠心，请简述这个标题的妙处。（3分）

20. 根据文章情节的发展，请在第 ⑥ 段的横线处补写出男孩此时的心理活动。（2分）

21. 第 ④ 段写到了女孩的哥哥，请结合文章内容，简析这一人物的出现在文中的具体作用。（2分）

22. 请筒析第 ⑮ 段在全文中的表达效果。（3分）

23. 结合文章主题，说说这篇文章给了你哪些启示 ？（3分）`,
    }
);

for (const doc of docs) {
    let content = doc.content;
    content = content.trim();
    if (doc.title && content.startsWith(doc.title)) {
        content = content.slice(doc.title.length);
        content = content.trim();
    }
    if (doc.author && content.startsWith(doc.author)) {
        content = content.slice(doc.author.length);
        content = content.trim();
    }
    content = content.replaceAll(/\n+/g, '\n');
    doc.content = content;
}

// @ts-ignore
function format(raw) {
    var r = raw.replaceAll(/\s*[,，]\s*/g, '，');
    console.clear();
    console.log(r);
}
